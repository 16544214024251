import React, { useEffect } from 'react';

import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { isDraggingBodyClass } from './isDraggingBodyCSSClass';

type TreeMonitorProps = {
	setIsDraggingItem?: (arg: boolean) => void;
	/* Note: changes to `itemItem` during a drag operation is not supported */
	itemType?: string;
};

export function TreeMonitor({ setIsDraggingItem, itemType = 'tree-item' }: TreeMonitorProps) {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	useEffect(() => {
		return monitorForElements({
			canMonitor: ({ source }) => source.data.type === itemType,
			onGenerateDragPreview: () => {
				setIsDraggingItem?.(true);
				document.body.classList.add(isDraggingBodyClass);
			},
			onDrop: () => {
				setIsDraggingItem?.(false);
				document.body.classList.remove(isDraggingBodyClass);
			},
		});
	}, [setIsDraggingItem, itemType]);

	// Safety: removing the dragging class from the body if the component is unmounted
	useEffect(function mount() {
		return function unmount() {
			document.body.classList.remove(isDraggingBodyClass);
		};
	}, []);

	return <div data-testid="tree-monitor" data-vc="tree-monitor" {...ssrPlaceholderIdProp} />;
}
